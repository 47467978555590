<template lang="pug">
v-app-bar#core-app-bar(
color="primary"
app
absolute
flat height='92')
  v-toolbar-title
    v-img(:src="logo" @click="$route.name!='team' || user ? $router.push({name:'main'}):''").pointer
  v-spacer(v-if="$route.name!='team'")
  v-toolbar-items(v-if="$route.name!='team'")
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-list-item(v-on="on" two-line @click="getOut")
          v-icon fas fa-sign-out-alt
      span Salir
</template>
<script>
import UserDropdown from '@/layouts/utils/UserDropdown'
import { mapMutations } from 'vuex'

export default {
  components: { UserDropdown },
  data () {
    return {
      notifications: [],
      title: 'Tablero de control',
      responsive: false
    }
  },
  computed: {
    logo () {
      return require('@/assets/logo.svg')
    },
    user () {
      return this.$firebase.auth().currentUser
    }
  },
  watch: {
    '$route' (val) {
      this.title = val.meta.show_name ? val.meta.show_name : val.name
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    getOut () {
      const self = this
      this.$firebase.auth().signOut().then(saliste => {
        console.log(saliste)
        this.$router.push({ name: 'login' })
      })
    },
    onClick () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style>
  /* Fix coming in v2.0.8 */
  #core-app-bar {
    width: auto;
  }

  #core-app-bar a {
    text-decoration: none;
  }
</style>
